import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import EnhancedTableHead from '../../Utils/EnhancedTableHead';
import { getComparator, stableSort } from '../../Utils/SortArray';
import formatRaceDate from '../../Utils/FormatRaceDate';
import fetchWithAuth from '../../Utils/FetchWithAuth';
import EditableCell from './EditableCell';
import useToken from '../../App/useToken';

function ExceptionDataTable({ data, exceptionType, onDataUpdate, selectedSummaryType }) {
  const { token } = useToken();
  
  // Define head cells based on exceptionType
  let headCells = [];
  switch (exceptionType) {
    case 'Unrated Races':
      headCells = [
        { id: 'ctryCode', numeric: false, label: 'Country Code' },
        { id: '_id', numeric: false, label: 'Smart Race ID' },
        { id: 'raceDate', numeric: false, label: 'Race Date' },
        { id: 'raceTime', numeric: false, label: 'Race Time' },
        { id: 'course', numeric: false, label: 'Course' },
        { id: 'PreRaceRated', numeric: true, label: 'Pre Race Rated', align: 'left' },
        { id: 'SmartRated', numeric: true, label: 'Smart Rated', align: 'left' },
        { id: 'WinSmartTimeExist', numeric: true, label: 'Smart Time Exists', align: 'left' },
        { id: 'note', numeric: false, label: 'Note', align: 'left' },
      ];
      break;
    case 'General Exceptions':
      headCells = [
        { id: 'smartDate', numeric: false, label: 'Date' },
        { id: 'smartMeetID', numeric: false, label: 'Smart Meet ID' },
        { id: 'smartRaceID', numeric: false, label: 'Smart Race ID' },
        { id: 'c5', numeric: false, label: 'Course' },
        { id: 'RaceTitle', numeric: false, label: 'Race Title' },
        { id: 'matchType', numeric: false, label: 'Match Type' },
        { id: 'HorseName', numeric: false, label: 'Horse Name' },
        { id: 'PreOrPost', numeric: false, label: 'Pre/Post' },
        { id: 'ExceptionLevel', numeric: false, label: 'Level' },
        { id: 'ExceptionType', numeric: false, label: 'Type' },
        { id: 'RaceSkeleton', numeric: false, label: 'Race Skeleton' },
        { id: 'SkeletonMatches', numeric: false, label: 'Skeleton Matches' },
        { id: 'note', numeric: false, label: 'Note', align: 'left' },
      ];
      break;
    case 'General Exception Summaries':
      headCells = [
        { id: 'year', numeric: true, label: 'Year', align: 'left' },
        { id: 'ExceptionType', numeric: false, label: 'Type' },
        { id: 'count', numeric: true, label: 'Count', align: 'left' },
        { id: 'pctVsSpecificException', numeric: true, label: '% vs Exception', align: 'left' },
        { id: 'pctOfSubset', numeric: true, label: '% of Subset', align: 'left' },
        { id: 'pctOfTotal', numeric: true, label: '% of Total', align: 'left' },
        { id: 'Checked', numeric: true, label: 'Checked', align: 'left' },
        { id: 'note', numeric: false, label: 'Note', align: 'left' },
      ];
      break;
    case 'History Vs Live':
      headCells = [
        { id: 'smartDate', numeric: false, label: 'Smart Date', align: 'left' },
        { id: 'smartRaceID', numeric: false, label: 'Smart Race ID', align: 'left' },
        { id: 'HorseName', numeric: false, label: 'Horse Name', align: 'left' },
        { id: 'ExceptionType', numeric: false, label: 'Exception Type', align: 'left' },
        { id: 'historyValue', numeric: true, label: 'History Value', align: 'left' },
        { id: 'liveValue', numeric: true, label: 'Live Value', align: 'left' },
        { id: 'Difference', numeric: true, label: 'Difference', align: 'left' },
        { id: 'note', numeric: false, label: 'Note', align: 'left' },
      ];
      break;
    default:
      break;
  }

  // Set initial sort order based on table type
  const [order, setOrder] = React.useState(
    exceptionType === 'Unrated Races' || 
    exceptionType === 'General Exceptions' || 
    exceptionType === 'History Vs Live' ? 'desc' : 'asc'
  );
  const [orderBy, setOrderBy] = React.useState(
    exceptionType === 'Unrated Races' ? 'raceDate' :
    exceptionType === 'General Exceptions' ? 'smartDate' :
    exceptionType === 'History Vs Live' ? 'smartDate' :
    headCells[0]?.id || ''
  );
  const [initialLoad, setInitialLoad] = React.useState(true);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setInitialLoad(false);
  };

  const handleSaveNote = async (rowId, newNote) => {
    try {
      console.log('Table type:', exceptionType);
      console.log('Row ID:', rowId);
      
      // Find the row data using the correct identifier
      const rowData = data.find(row => {
        switch (exceptionType) {
          case 'Unrated Races':
            return row._id === rowId;
          case 'General Exceptions':
            return row._id === rowId;
          case 'History Vs Live':
            return row._id === rowId;
          case 'General Exception Summaries':
            return row._id === rowId;
          default:
            return row._id === rowId;
        }
      });

      if (!rowData) {
        console.error('Could not find row data for ID:', rowId);
        return;
      }

      // Get the appropriate collection type and country code
      let collection, countryCode;
      
      switch (exceptionType) {
        case 'Unrated Races':
          collection = 'unrated';
          countryCode = rowData.ctryCode;
          break;
        case 'General Exceptions':
          collection = 'general';
          countryCode = rowData._id.split('_')[0];
          break;
        case 'History Vs Live':
          collection = 'history';
          countryCode = rowData._id.split('_')[0];
          break;
        case 'General Exception Summaries':
          collection = 'summary';
          // For summaries, we don't need to send the countryCode as it's in the _id
          countryCode = null;
          break;
        default:
          collection = 'summary';
          countryCode = rowData.countryCode;
      }

      const payload = {
        id: rowData._id,
        note: newNote,
        countryCode,
        collection
      };

      console.log('Sending payload:', payload);
      
      const response = await fetchWithAuth(
        'updateNote',
        payload,
        token,
        'POST'
      );
      
      if (response.success) {
        if (onDataUpdate) {
          onDataUpdate();
        }
      } else {
        console.error('Failed to update note:', response);
      }
    } catch (error) {
      console.error('Error updating note:', error);
    }
  };

  const formatCellValue = (cell, value) => {
    if (value === undefined || value === null) return '';
    
    if (cell.id === 'raceDate') {
      return formatRaceDate(value);
    }
    
    // Special handling for unrated races binary columns
    if (['PreRaceRated', 'SmartRated', 'WinSmartTimeExist'].includes(cell.id)) {
      return typeof value === 'number' ? Math.round(value) : value;
    }
    
    if (cell.numeric) {
      return typeof value === 'number' ? value.toFixed(2) : value;
    }
    
    return value;
  };

  const [sortedRows, setSortedRows] = React.useState([]);

  React.useEffect(() => {
    if (Array.isArray(data)) {
      let processedData = data;

      // Filter out TEST entries and null/empty dates for General Exceptions
      if (exceptionType === 'General Exceptions') {
        processedData = data.filter(row => {
          // Filter out rows with TEST in any field
          const hasTest = Object.values(row).some(value => 
            typeof value === 'string' && value.toUpperCase().includes('TEST')
          );
          
          // Filter out rows with null/empty dates
          const hasValidDate = row.smartDate && row.smartDate.trim() !== '';
          
          return !hasTest && hasValidDate;
        });
      }

      // Filter Exception Summaries by type if selected
      if (exceptionType === 'General Exception Summaries' && selectedSummaryType !== 'All') {
        processedData = processedData.filter(row => row.ExceptionType === selectedSummaryType);
      }

      // Apply initial sort for specific table types
      if (initialLoad && (exceptionType === 'Unrated Races' || 
                         exceptionType === 'General Exceptions' || 
                         exceptionType === 'History Vs Live')) {
        const sortField = exceptionType === 'Unrated Races' ? 'raceDate' : 'smartDate';
        const sortedData = stableSort(processedData, getComparator('desc', sortField));
        setSortedRows(sortedData);
      } else {
        setSortedRows(stableSort(processedData, getComparator(order, orderBy)));
      }
    } else {
      setSortedRows([]);
    }
  }, [order, orderBy, data, exceptionType, initialLoad, selectedSummaryType]);

  const renderCell = (row, cell) => {
    if (cell.id === 'note') {
      return (
        <EditableCell
          key={cell.id}
          initialValue={row[cell.id]}
          rowId={row._id}
          onSave={(rowId, newValue) => handleSaveNote(rowId, newValue)}
        />
      );
    }
  
    return (
      <TableCell
        key={cell.id}
        align={cell.align || (cell.numeric ? 'right' : 'left')}
        sx={{ minWidth: cell.id === 'note' ? 200 : 'auto' }}
      >
        {formatCellValue(cell, row[cell.id])}
      </TableCell>
    );
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
      <TableContainer>
        <Table stickyHeader aria-label="exception table">
          <EnhancedTableHead
            headCells={headCells}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {sortedRows.map((row, rowIndex) => (
              <TableRow key={row._id || rowIndex} hover>
                {headCells.map((cell) => renderCell(row, cell))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

export default ExceptionDataTable;