import React, { useState, useEffect } from 'react';
import { Card, CardContent } from '../ui/card';
import { AlertCircle, BarChart3, Clock, History, TrendingUp } from 'lucide-react';
import fetchWithAuth from '../Utils/FetchWithAuth';
import useToken from '../App/useToken';
import ExceptionDataTable from './components/ExceptionDataTable';

const EnhancedTabDashboard = () => {
  const { token } = useToken();
  const [activeTab, setActiveTab] = useState('unrated');
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState({
    unrated: [],
    general: [],
    summary: [],
    history: []
  });

  // Common state
  const [countryCode, setCountryCode] = useState('All');

  // Unrated Races state
  const [unratedToDate, setUnratedToDate] = useState(() => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return yesterday.toISOString().split('T')[0];
  });

  // History vs Live state
  const [historyFromDate, setHistoryFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 5)).toISOString().split('T')[0]
  );
  const [historyToDate, setHistoryToDate] = useState('');
  const [selectedExceptionType, setSelectedExceptionType] = useState('All');

  // Summary state
  // Summary state
  const [selectedYear, setSelectedYear] = useState('All');
  const [selectedSummaryType, setSelectedSummaryType] = useState('All');
  const [uniqueSummaryTypes, setUniqueSummaryTypes] = useState([]);

  // Update unique summary types whenever data changes
  useEffect(() => {
    if (data.summary && data.summary.length > 0) {
      const types = [...new Set(data.summary.map(item => item.ExceptionType))].sort();
      setUniqueSummaryTypes(types);
    }
  }, [data.summary]);

  // Summary widgets state
  const [summaryData, setSummaryData] = useState({
    totalExceptions: 0,
    unratedRaces: 0,
    historyConflicts: 0,
    ratingDiffs: 0
  });

  const tabs = [
    { id: 'unrated', label: 'Unrated Races', icon: Clock },
    { id: 'general', label: 'General Exceptions', icon: AlertCircle },
    { id: 'summary', label: 'Exception Summaries', icon: BarChart3 },
    { id: 'history', label: 'History vs Live', icon: History },
  ];

  const exceptionTypes = [
    "WinTimeDifference",
    "SmartRatingDifference",
    "HorseWgtCarriedDifference",
    "EffectWgtCarriedDifference",
    "BeatenDistanceDifference",
    "FinishingPositionDifference",
    "SmartFormDifference",
    "SmartTimeLiveDifference",
    "SmartTimeFullDifference",
    "SmartSecTimeDifference",
    "All",
  ];

  const currentYear = new Date().getFullYear();
  const years = [
    'All', 
    ...Array.from({length: 5}, (_, i) => (currentYear - i).toString())
      .sort((a, b) => b - a)  
  ];

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const countryList = countryCode === 'All' 
          ? ['GB_F', 'GB_J', 'IRE_F', 'IRE_J', 'FR_F']
          : [countryCode];
  
        let unratedResults = [];
        let generalResults = [];
        let historyResults = [];
  
        for (const code of countryList) {
          // Fetch unrated races
          const unratedData = await fetchWithAuth('unratedRacesExceptions', {
            countryCode: code,
            toDate: unratedToDate
          }, token);
          unratedResults = [...unratedResults, ...unratedData];
  
          // Fetch general exceptions
          const generalData = await fetchWithAuth('generalExceptions', {
            countryCode: code
          }, token);
          generalResults = [...generalResults, ...generalData];
  
          // Fetch history data
          const historyData = await fetchWithAuth('historyLiveExceptions', {
            countryCode: code,
            fromDate: historyFromDate,
            toDate: historyToDate
          }, token);
          historyResults = [...historyResults, ...historyData];
        }
  
        // Update summary widgets with all data
        setSummaryData({
          totalExceptions: generalResults.length,
          unratedRaces: unratedResults.length,
          historyConflicts: historyResults.length,
          ratingDiffs: generalResults.filter(e => e.ExceptionType === 'SmartRatingDifference').length
        });
  
        // Set initial data for each tab
        setData({
          unrated: unratedResults,
          general: generalResults,
          history: historyResults,
          summary: [] // This will be fetched when the summary tab is selected
        });
  
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };
  
    fetchInitialData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const countryList = countryCode === 'All' 
          ? ['GB_F', 'GB_J', 'IRE_F', 'IRE_J', 'FR_F']
          : [countryCode];
  
        let results = [];
  
        for (const code of countryList) {
          let endpoint, params;
  
          switch(activeTab) {
            case 'unrated':
              endpoint = 'unratedRacesExceptions';
              params = { 
                countryCode: code, 
                toDate: unratedToDate || undefined 
              };
              break;
  
            case 'general':
              endpoint = 'generalExceptions';
              params = { countryCode: code };
              break;
  
            case 'summary':
              endpoint = 'generalExceptionSummaries';
              params = { 
                countryCode: code,
                year: selectedYear !== 'All' ? parseInt(selectedYear) : undefined  // Convert to number
              };
              break;
  
            case 'history':
              endpoint = 'historyLiveExceptions';
              params = {
                countryCode: code,
                fromDate: historyFromDate || undefined,
                toDate: historyToDate || undefined,  // Don't fallback to selectedDate
                exceptionType: selectedExceptionType || undefined
              };
              console.log('Fetching history data with params:', params);  // Debug log
              break;
  
            default:
              continue;
          }
  
          const response = await fetchWithAuth(endpoint, params, token);
          console.log(`${activeTab} response for ${code}:`, response); // Debug log
          results = [...results, ...response];
        }
  
        // Update data and summary stats
        setData(prevData => ({ ...prevData, [activeTab]: results }));
        updateSummaryStats(activeTab, results);
  
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, [
    activeTab, 
    countryCode, 
    unratedToDate,
    historyFromDate,
    historyToDate,
    selectedExceptionType,
    selectedYear,
    token
  ]);

  const updateSummaryStats = (tab, results) => {
    setSummaryData(prevStats => ({
      ...prevStats,
      totalExceptions: tab === 'general' ? results.length : prevStats.totalExceptions,
      unratedRaces: tab === 'unrated' ? results.length : prevStats.unratedRaces,
      historyConflicts: tab === 'history' ? results.length : prevStats.historyConflicts,
      ratingDiffs: tab === 'general' 
        ? results.filter(e => e.ExceptionType === 'SmartRatingDifference').length 
        : prevStats.ratingDiffs
    }));
  };

  const refreshCurrentTabData = async () => {
    setIsLoading(true);
    try {
      const countryList = countryCode === 'All' 
        ? ['GB_F', 'GB_J', 'IRE_F', 'IRE_J', 'FR_F']
        : [countryCode];
  
      let results = [];
  
      for (const code of countryList) {
        let endpoint, params;
  
        switch(activeTab) {
          case 'unrated':
            endpoint = 'unratedRacesExceptions';
            params = { 
              countryCode: code, 
              toDate: unratedToDate || undefined 
            };
            break;
          
          case 'general':
            endpoint = 'generalExceptions';
            params = { countryCode: code };
            break;
  
          case 'summary':
            endpoint = 'generalExceptionSummaries';
            params = { 
              countryCode: code,
              year: selectedYear !== 'All' ? parseInt(selectedYear) : undefined  // Convert to number
            };
            break;
  
          case 'history':
            endpoint = 'historyLiveExceptions';
            params = {
              countryCode: code,
              fromDate: historyFromDate || undefined,
              toDate: historyToDate || undefined,
              exceptionType: selectedExceptionType || undefined
            };
            console.log('Fetching history data with params:', params);
            break;
  
          default:
            continue;
        }
        
        const response = await fetchWithAuth(endpoint, params, token);
        results = [...results, ...response];
      }
  
      setData(prevData => ({
        ...prevData,
        [activeTab]: results
      }));
  
    } catch (error) {
      console.error('Error refreshing data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // Render filter controls based on active tab
  const renderFilters = () => {
    switch(activeTab) {
      case 'unrated':
        return (
          <div className="flex gap-4 mb-4">
            <div className="flex flex-col">
              <label className="text-sm text-gray-600 mb-1">Latest Race Date</label>
              <input
                type="date"
                value={unratedToDate}
                onChange={(e) => setUnratedToDate(e.target.value)}
                className="flex h-10 rounded-md border border-input bg-background px-3 py-2"
              />
            </div>
          </div>
        );

      case 'history':
        return (
          <div className="flex gap-4 mb-4">
            <div className="flex flex-col">
              <label className="text-sm text-gray-600 mb-1">From Date</label>
              <input
                type="date"
                value={historyFromDate}
                onChange={(e) => setHistoryFromDate(e.target.value)}
                className="flex h-10 rounded-md border border-input bg-background px-3 py-2"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm text-gray-600 mb-1">To Date</label>
              <input
                type="date"
                value={historyToDate}
                onChange={(e) => setHistoryToDate(e.target.value)}
                className="flex h-10 rounded-md border border-input bg-background px-3 py-2"
              />
            </div>
            <div className="flex flex-col">
              <label className="text-sm text-gray-600 mb-1">Exception Type</label>
              <select
                value={selectedExceptionType}
                onChange={(e) => setSelectedExceptionType(e.target.value)}
                className="flex h-10 rounded-md border border-input bg-background px-3 py-2"
              >
                {exceptionTypes.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
            </div>
          </div>
        );

        case 'summary':
          return (
            <div className="flex gap-4 mb-4">
              <div className="flex flex-col">
                <label className="text-sm text-gray-600 mb-1">Filter by Year</label>
                <select
                  value={selectedYear}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  className="flex h-10 w-48 rounded-md border border-input bg-background px-3 py-2"
                >
                  {years.map((year) => (
                    <option key={year} value={year}>{year}</option>
                  ))}
                </select>
              </div>
  
              <div className="flex flex-col">
                <label className="text-sm text-gray-600 mb-1">Filter by Type</label>
                <select
                  value={selectedSummaryType}
                  onChange={(e) => setSelectedSummaryType(e.target.value)}
                  className="flex h-10 w-64 rounded-md border border-input bg-background px-3 py-2"
                >
                  <option value="All">All Types</option>
                  {uniqueSummaryTypes.map((type) => (
                    <option key={type} value={type}>{type}</option>
                  ))}
                </select>
              </div>
            </div>
          );

      default:
        return null;
    }
  };

  return (
    <div className="p-6 bg-gray-50 min-h-screen">
      <div className="max-w-[90%] mx-auto">
        {/* Header */}
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Exception Dashboard</h1>
          
          <div className="flex items-center gap-4">
            <div className="flex flex-col">
              <label className="text-sm text-gray-600 mb-1">Country Code</label>
              <select
                value={countryCode}
                onChange={(e) => setCountryCode(e.target.value)}
                className="flex h-10 rounded-md border border-input bg-background px-3 py-2"
              >
                <option value="GB_F">GB Flat</option>
                <option value="GB_J">GB Jump</option>
                <option value="IRE_F">IRE Flat</option>
                <option value="IRE_J">IRE Jump</option>
                <option value="FR_F">FR Flat</option>
                <option value="All">All</option>
              </select>
            </div>
          </div>
        </div>

        {/* Summary Widgets */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-6">
          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-500">Total Exceptions</p>
                  <p className="text-2xl font-bold">{summaryData.totalExceptions.toLocaleString()}</p>
                </div>
                <AlertCircle className="h-8 w-8 text-red-500" />
              </div>
            </CardContent>
          </Card>
          
          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-500">Unrated Races</p>
                  <p className="text-2xl font-bold">{summaryData.unratedRaces.toLocaleString()}</p>
                </div>
                <Clock className="h-8 w-8 text-yellow-500" />
              </div>
            </CardContent>
          </Card>
          
          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-500">History Conflicts</p>
                  <p className="text-2xl font-bold">{summaryData.historyConflicts.toLocaleString()}</p>
                </div>
                <History className="h-8 w-8 text-blue-500" />
              </div>
            </CardContent>
          </Card>
          
          <Card>
            <CardContent className="pt-6">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-500">Smart Rating Diffs</p>
                  <p className="text-2xl font-bold">{summaryData.ratingDiffs.toLocaleString()}</p>
                </div>
                <TrendingUp className="h-8 w-8 text-green-500" />
              </div>
            </CardContent>
          </Card>
        </div>

        {/* Tab Navigation */}
        <div className="border-b border-gray-200 mb-6">
          <nav className="flex -mb-px space-x-8" aria-label="Tabs">
            {tabs.map((tab) => {
              const Icon = tab.icon;
              return (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`
                    group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm
                    ${activeTab === tab.id
                      ? 'border-blue-500 text-blue-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}
                  `}
                >
                  <Icon className="h-5 w-5 mr-2" />
                  {tab.label}
                </button>
              );
            })}
          </nav>
        </div>

        {/* Refresh Note */}
        <div className="flex items-center mb-4 p-2 bg-blue-50 text-blue-700 rounded-md">
          <span className="text-sm">
            Note: Changes to notes by other users require a page refresh (F5 or Ctrl+Shift+R) to be visible
          </span>
        </div>

        {/* Tab Content */}
        <Card>
          <CardContent className="p-6">
            {/* Render filters for current tab */}
            {renderFilters()}

            {/* Table */}
            <div className="border border-gray-200 rounded-lg bg-white">
              {isLoading ? (
                <div className="flex justify-center items-center p-4">
                  <p>Loading data...</p>
                </div>
              ) : (
                <ExceptionDataTable 
                  data={data[activeTab]}
                  exceptionType={
                    activeTab === 'unrated' ? 'Unrated Races' :
                    activeTab === 'general' ? 'General Exceptions' :
                    activeTab === 'summary' ? 'General Exception Summaries' :
                    'History Vs Live'
                  }
                  selectedSummaryType={selectedSummaryType}
                  onDataUpdate={refreshCurrentTabData}
                />
              )}
            </div>
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default EnhancedTabDashboard;