import React, { useState, useRef, useEffect } from 'react';
import TableCell from '@mui/material/TableCell';

const EditableCell = ({ initialValue, rowId, onSave }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(initialValue || '');
  const inputRef = useRef(null);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (value !== initialValue) {
      console.log('Saving with rowId:', rowId); // Debug log
      console.log('Saving value:', value);      // Debug log
      onSave(rowId, value);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleBlur();
    }
  };

  return (
    <TableCell
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
      }}
    >
      {isEditing ? (
        <input
          ref={inputRef}
          type="text"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          onBlur={handleBlur}
          onKeyPress={handleKeyPress}
          className="w-full p-1 border border-blue-500 rounded"
        />
      ) : (
        <div onClick={handleClick}>
          {value || 'Click to add note'}
        </div>
      )}
    </TableCell>
  );
};

export default EditableCell;