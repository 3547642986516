import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';
import logo from '../../images/SR.svg';
import getRole from '../Utils/getRole';
import DropdownMenu from './components/DropdownMenu';

export default function NavBar() {
  const location = useLocation();
  const role = getRole();

  const raceCardsMenuItems = [
    { label: 'GB_F', href: '/GB_F' },
    { label: 'GB_J', href: '/GB_J' },
    { label: 'IRE_F', href: '/IRE_F' },
    { label: 'IRE_J', href: '/IRE_J' },
    { label: 'FR_F', href: '/FR_F', showForRole: 'admin' },
  ];

  const liveGoingMenuItems = [
    { label: 'GB & IRE', href: '/GoingAdjustments' },
    { label: 'FR', href: '/GoingAdjustmentsFR' },
  ];

  const leaderboardMenuItems = [
    { label: 'GB & IRE Flat', href: '/LeaderBoardFlat' },
    { label: 'GB & IRE Jumps', href: '/LeaderBoardJump' },
    { label: 'FR Flat', href: '/LeaderBoardFlat_FR' },
  ];

  const exceptionMenuItems = [
    { label: 'Exception Dashboards', href: '/ExceptionDashboard' },
  ];

  const searchMenuItems = [
    { label: 'GB & IRE', href: '/Search' },
    { label: 'FR', href: '/SearchFR' },
  ];

  return (
    <Disclosure as="nav" className="relative z-50 pb-0 lg:pt-0 shadow-lg">
      {({ open }) => (
        <>
          <div className={`mx-auto max-w-7xl px-2 sm:px-6 lg:px-8`}>
            <div className="relative flex h-16 justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center sm:items-stretch sm:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img className="block h-8 w-auto lg:hidden" src={logo} alt="JHJW Consulting" />
                  <img className="hidden h-8 w-auto lg:block" src={logo} alt="JHJW Consulting" />
                </div>
                <div className="hidden sm:ml-6 sm:flex sm:space-x-8 items-center">
                  <a
                    href="/"
                    className={location.pathname === '/'
                      ? 'inline-flex items-center border-b-2 border-blue-400 px-1 pt-1 text-base font-medium text-gray-900'
                      : 'inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-base font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'}
                  >
                    Home
                  </a>

                  {/* Use DropdownMenu component */}
                  <DropdownMenu
                    title="Race Cards"
                    menuItems={raceCardsMenuItems}
                    activePaths={['/GB_F', '/GB_J', '/IRE_F', '/IRE_J', '/FR_F']}
                    role={role}
                  />
                  
                  {/* Conditional Live Going */}
                  {role === 'admin' ? (
                    <DropdownMenu
                      title="Live Going"
                      menuItems={liveGoingMenuItems}
                      activePaths={['/GoingAdjustments', '/GoingAdjustmentsFR']}
                      role={role}
                    />
                  ) : (
                    <a
                      href="/GoingAdjustments"
                      className={location.pathname === '/GoingAdjustments'
                        ? 'inline-flex items-center border-b-2 border-blue-400 px-1 pt-1 text-base font-medium text-gray-900'
                        : 'inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-base font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'}
                    >
                      Live Going
                    </a>
                  )}


                  {/* Conditional Leaderboard */}
                  {role === 'admin' ? (
                    <DropdownMenu
                      title="Leaderboards"
                      menuItems={leaderboardMenuItems}
                      activePaths={['/LeaderBoardFlat', '/LeaderBoardFlat_FR', '/LeaderBoardJump']}
                      role={role}
                    />
                  ) : (
                    <>
                      <a
                        href="/LeaderBoardFlat"
                        className={location.pathname === '/LeaderBoardFlat'
                          ? 'inline-flex items-center border-b-2 border-blue-400 px-1 pt-1 text-base font-medium text-gray-900'
                          : 'inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-base font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'}
                      >
                        Leaderboard Flat
                      </a>
                      <a
                        href="/LeaderBoardJump"
                        className={location.pathname === '/LeaderBoardJump'
                          ? 'inline-flex items-center border-b-2 border-blue-400 px-1 pt-1 text-base font-medium text-gray-900'
                          : 'inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-base font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'}
                      >
                        Leaderboard Jumps
                      </a>
                    </>
                  )}

                  
                  {/* Conditional Search */}
                  {role === 'admin' ? (
                    <DropdownMenu
                      title="Search"
                      menuItems={searchMenuItems}
                      activePaths={['/Search', '/SearchFR']}
                      role={role}
                    />
                  ) : (
                    <a
                      href="/Search"
                      className={location.pathname === '/Search'
                        ? 'inline-flex items-center border-b-2 border-blue-400 px-1 pt-1 text-base font-medium text-gray-900'
                        : 'inline-flex items-center border-b-2 border-transparent px-1 pt-1 text-base font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700'}
                    >
                      Search
                    </a>
                  )}
                  
                  
                  
                  {role === 'admin' && exceptionMenuItems.map((item) => (
                    <Disclosure.Button
                      key={item.href}
                      as="a"
                      href={item.href}
                      className={location.pathname === item.href
                        ? 'block border-l-4 border-blue-400 bg-blue-50 py-2 pl-3 pr-4 text-base font-medium text-blue-700'
                        : 'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'}
                    >
                      {item.label}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 pb-4 pt-2">
              {/* Home Link */}
              <Disclosure.Button
                as="a"
                href="/"
                className={location.pathname === '/'
                  ? 'block border-l-4 border-blue-400 bg-blue-50 py-2 pl-3 pr-4 text-base font-medium text-blue-700'
                  : 'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'}
              >
                Home
              </Disclosure.Button>

              {/* Race Cards Links */}
              {raceCardsMenuItems.map((item) => (
                (!item.showForRole || item.showForRole === role) && (
                  <Disclosure.Button
                    as="a"
                    href={item.href}
                    key={item.href}
                    className={location.pathname === item.href
                      ? 'block border-l-4 border-blue-400 bg-blue-50 py-2 pl-3 pr-4 text-base font-medium text-blue-700'
                      : 'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'}
                  >
                    {item.label}
                  </Disclosure.Button>
                )
              ))}

              {/* Additional Links */}
              <Disclosure.Button
                as="a"
                href="/GoingAdjustments"
                className={location.pathname === '/GoingAdjustments'
                  ? 'block border-l-4 border-blue-400 bg-blue-50 py-2 pl-3 pr-4 text-base font-medium text-blue-700'
                  : 'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'}
              >
                Live Going
              </Disclosure.Button>
              {role === 'admin' && (
                <Disclosure.Button
                  as="a"
                  href="/GoingAdjustmentsFR"
                  className={location.pathname === '/GoingAdjustmentsFR'
                    ? 'block border-l-4 border-blue-400 bg-blue-50 py-2 pl-3 pr-4 text-base font-medium text-blue-700'
                    : 'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'}
                >
                  Live Going FR
                </Disclosure.Button>
              )}
              <Disclosure.Button
                as="a"
                href="/LeaderBoardFlat"
                className={location.pathname === '/LeaderBoardFlat'
                  ? 'block border-l-4 border-blue-400 bg-blue-50 py-2 pl-3 pr-4 text-base font-medium text-blue-700'
                  : 'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'}
              >
                Leaderboard Flat
              </Disclosure.Button>
              {role === 'admin' && (
                <Disclosure.Button
                  as="a"
                  href="/LeaderBoardFlat_FR"
                  className={location.pathname === '/LeaderBoardFlat_FR'
                    ? 'block border-l-4 border-blue-400 bg-blue-50 py-2 pl-3 pr-4 text-base font-medium text-blue-700'
                    : 'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'}
                >
                  Leaderboard FR Flat
                </Disclosure.Button>
              )}
              <Disclosure.Button
                as="a"
                href="/LeaderBoardJump"
                className={location.pathname === '/LeaderBoardJump'
                  ? 'block border-l-4 border-blue-400 bg-blue-50 py-2 pl-3 pr-4 text-base font-medium text-blue-700'
                  : 'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'}
              >
                Leaderboard Jumps
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/Search"
                className={location.pathname === '/Search'
                  ? 'block border-l-4 border-blue-400 bg-blue-50 py-2 pl-3 pr-4 text-base font-medium text-blue-700'
                  : 'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'}
              >
                Search
              </Disclosure.Button>
              {role === 'admin' && (
                <Disclosure.Button
                  as="a"
                  href="/ExceptionDashboard"
                  className={location.pathname === '/ExceptionDashboard'
                    ? 'block border-l-4 border-blue-400 bg-blue-50 py-2 pl-3 pr-4 text-base font-medium text-blue-700'
                    : 'block border-l-4 border-transparent py-2 pl-3 pr-4 text-base font-medium text-gray-500 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-700'}
                >
                  Exception Dashboards
                </Disclosure.Button>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}